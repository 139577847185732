const DATE_TIME = 'Scheduled Date / Time: ';
const START_TIME = 'Actual Start Time: ';
const KPHC_CSN_ID = 'KPHC CSN ID: ';
const TOTAL_PARTICIPANTS = 'Total Participants: ';
const DURATION = 'Duration: ';
const NAME = 'Name';
const ATTENDANCE_TYPE = 'Attendee Type';
const JOIN_TIME  = 'Join Time';
const LEAVE_TIME  = 'Leave Time';
const TIME_ATTENDED= 'Time Attended';

const FILTER_TITLE = "Report Date Range";
const LEFT_SIDE_REPORT_HEADER_DATE_FORMAT = "MMM DD, YYYY";
const CALENDAR_SETTINGS = {
    datePickerInput: {
        className: "vve-date-picker-input",
        showIcon: true,
        toggleCalendarOnIconClick: true
    },
    calendarPopup: {
        calendarClassName: "vve-calendar-body",
        showPopperArrow: false,
    },
    startDate: {
        pastDaysLimit: 30,
        popperPlacement: "bottom",
        popperClassName: "vve-calendar-container-start"
    },
    endDate: {
        popperPlacement: "bottom",
        popperClassName: "vve-calendar-container-end"
    }
};

const NO_MORE_RECORDS_MESSAGE = {
    noResponse: "There are no records to display",
    endOfRecords: "There are no more records to display"
};

const FILTER_REPORT_DATE_FORMAT = "MM/DD/YYYY";

const REPORT_DOWNLOAD_FAILED_MODAL_OPTIONS = {
    title: "The report has failed to download.",
    body: "Please try again."
};


export {
    DATE_TIME,
    START_TIME,
    KPHC_CSN_ID,
    DURATION,
    NAME,
    ATTENDANCE_TYPE,
    JOIN_TIME,
    LEAVE_TIME,
    TOTAL_PARTICIPANTS,
    FILTER_TITLE,
    LEFT_SIDE_REPORT_HEADER_DATE_FORMAT,
    CALENDAR_SETTINGS,
    NO_MORE_RECORDS_MESSAGE,
    FILTER_REPORT_DATE_FORMAT,
    REPORT_DOWNLOAD_FAILED_MODAL_OPTIONS,
    TIME_ATTENDED
};
